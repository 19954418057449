
$baseColor: #FF1E00; // orange

$textColor: #333;
$fontSize: 14px;
$lineHeight: 2.4;
$letterSpacing: .1em;

$fontBase: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", 'Open Sans', YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
$fontJp: "Noto Sans Japanese";
$fontEn: 'Roboto', sans-serif;
$fontEnNarrow: 'Roboto Condensed', sans-serif;

$easeCubic: cubic-bezier(0.19, 1, 0.22, 1), -webkit-box-shadow 0.65s cubic-bezier(0.19, 1, 0.22, 1);

@mixin easeOutExpo{
	transition: all 2s cubic-bezier(0.190, 1.000, 0.220, 1.000);
	transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);
}
