@charset "UTF-8";
#main-view-wrap {
  position: relative;
  z-index: 1;
  margin-top: -5px;
}

#main-view {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  transform: skewY(-5deg);
}

#main-view span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../svg/top/main.svg);
  background-size: 400px auto;
  transform: skewY(5deg) scale(1.3);
  animation: bgscroll 600s linear infinite;
}

#main-view span:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #FF1E00;
  opacity: .8;
}

#main-desc {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  color: #fff;
  transform: translate(-50%, -50%);
}

#main-desc h2 img {
  width: 50%;
  max-width: 250px;
}

#main-desc p {
  color: #f0e68c;
}

#main-desc a.pdf {
  display: inline-block;
  width: 80%;
  max-width: 300px;
  margin: 40px 0 0;
  padding: 10px;
  border: 5px solid #ff624d;
  background-color: #FF1E00;
  transition: border .3s;
}

#main-desc a.pdf:hover {
  border: 5px solid #ff7866;
}

#main-desc .sns {
  margin: 30px 0 0;
}

#main-desc .movie {
  margin: 30px auto 0;
  width: 80%;
  max-width: 700px;
}

#main-desc .movie span {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
}

#main-desc .movie span iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.sec.sec01 {
  background-color: #E6E6E6;
}

.sec.sec01 .sec-foot {
  margin: 40px 0 -20px;
}

.sec.sec01 .sec-foot .contact {
  text-align: center;
  position: relative;
  width: 90%;
  max-width: 700px;
  margin: auto;
  padding: 20px 0 25px;
  font-weight: 500;
  line-height: 2;
}

.sec.sec01 .sec-foot .contact strong,
.sec.sec01 .sec-foot .contact a {
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  transition: opacity 1s 1s;
}

.sec.sec01 .sec-foot .contact a {
  color: #FF1E00;
}

.sec.sec01 .sec-foot .contact:before,
.sec.sec01 .sec-foot .contact:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid #fff;
  transition: width 1s;
}

.sec.sec01 .sec-foot .contact:before {
  top: 0;
  left: 0;
}

.sec.sec01 .sec-foot .contact:after {
  bottom: 0;
  right: 0;
}

.sec.sec01 .sec-foot .contact.on strong,
.sec.sec01 .sec-foot .contact.on a {
  opacity: 1;
}

.sec.sec01 .sec-foot .contact.on:before,
.sec.sec01 .sec-foot .contact.on:after {
  width: 100%;
}

.sec.sec01:before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 50%;
  background-color: #E6E6E6;
  transform: translateY(-90%) skewY(10deg);
}

.sec.sec01:after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25%;
  background-color: #464646;
  transform: translateY(45%);
}

.sec.sec02 {
  position: relative;
  z-index: 2;
}

.sec.sec02 .sec-body .fig {
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 0;
}

.sec.sec02 .sec-body .fig.fig01 {
  margin-top: -5%;
}

.sec.sec02 .sec-body .fig.fig01 img {
  max-width: 960px;
}

.sec.sec02 .sec-body .fig.fig02 {
  margin-top: -8%;
}

.sec.sec02 .sec-body .fig.fig02 img {
  max-width: 1100px;
}

.sec.sec02 .sec-body .games {
  position: relative;
  z-index: 1;
  max-width: 1100px;
  margin: -5% auto 0;
}

.sec.sec02 .sec-body .games .game .game-in {
  position: relative;
  top: 0;
  padding: 5% 7.5%;
  border-radius: 10px;
  background-color: #fff;
  border: 5px solid #FF1E00;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: top 0.65s cubic-bezier(0.19, 1, 0.22, 1), box-shadow 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.sec.sec02 .sec-body .games .game .game-in .game-head {
  line-height: 0;
}

.sec.sec02 .sec-body .games .game .game-in .game-body {
  margin: 15px 0 0;
}

.sec.sec02 .sec-body .games .game .game-in .game-body ul {
  line-height: 1.8;
}

.sec.sec02 .sec-body .games .game .game-in .game-body ul li {
  position: relative;
  padding: 3px 0 3px 1.2em;
}

.sec.sec02 .sec-body .games .game .game-in .game-body ul li:before {
  content: '・';
  position: absolute;
  top: 3px;
  left: 0;
}

.sec.sec02 .sec-body .games .game .game-in i {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #FF1E00;
  font-size: 24px;
}

.sec.sec02 .sec-body .games .game .game-in a.permalink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sec.sec02 .sec-body .games .game .game-in:hover {
  z-index: 1;
  top: -10px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.sec.sec02 .sec-body .games .game:not(:first-child) {
  margin: 20px 0 0;
}

.slant {
  position: relative;
  padding: 30px 7.5% 40px;
  margin: 0 -20px;
  color: #fff;
  /*
	h4:before{
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 200px;
		height: 1px;
		background-color: rgba(0,0,0,.2);
		transform: translateX(-50%);
	}
*/
}

.slant .in {
  opacity: 0;
  transition: opacity 1s .6s;
}

.slant h4 {
  text-align: center;
  position: relative;
  color: #fff;
  margin: 0 0 10px;
}

.slant p {
  color: #f0e68c;
}

.slant:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #FF1E00;
  transform: translate(-50%, -50%) skew(-5deg);
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
  transition: width 0.6s cubic-bezier(0.19, 1, 0.22, 1), -webkit-box-shadow 0.65s cubic-bezier(0.19, 1, 0.22, 1), height 0.3s 0.6s cubic-bezier(0.19, 1, 0.22, 1), -webkit-box-shadow 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.slant.on .in {
  opacity: 1;
}

.slant.on:before {
  width: 100%;
  height: 100%;
}

.slant:not(:first-of-type) {
  margin-top: 8%;
}

.sec.sec03 {
  padding-top: 0;
  background-color: #E6E6E6;
}

.sec.sec03 .sec-body ul li .li-in {
  position: relative;
  top: 0;
  left: 0;
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.65s cubic-bezier(0.19, 1, 0.22, 1), -webkit-box-shadow 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.sec.sec03 .sec-body ul li .li-in a {
  position: relative;
  display: block;
  line-height: 0;
}

.sec.sec03 .sec-body ul li .li-in a p {
  text-align: center;
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  height: auto;
  font-size: 14px;
  line-height: 2.4;
}

.sec.sec03 .sec-body ul li .li-in:hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.sec.sec03 .sec-body ul li:not(:first-child) {
  margin: 20px 0 0;
}

.sec.sec03:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #E6E6E6;
  transform: translateY(-40%) skewY(-10deg);
}

.sec.sec04 {
  padding-bottom: 0;
}

.sec.sec04 .sec-body .roadmap {
  max-width: 1130px;
  margin: 0 -10px;
}

.sec.sec05 {
  position: relative;
  z-index: 1;
  color: #fff;
}

.sec.sec05 .sec-head h3 {
  color: #fff;
}

.sec.sec05 .sec-head h3:before,
.sec.sec05 .sec-head h3:after {
  background-color: #fff;
}

.sec.sec05:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*
	background: $baseColor url(../svg/top/sec05/pattern.svg);
	background-size: 55px auto;
	transform: skewY(10deg) scale(2);
*/
  background-color: #FF1E00;
  transform: skewY(-10deg);
}

.sec05-btm {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 50%;
  background-color: #e6e6e6;
  margin-top: -43%;
}

.sec05-btm:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 5%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #464646;
  transform: skewY(10deg);
}

#member ul {
  margin: 0 0 -30px;
}

#member ul li {
  margin: 0 0 30px;
}

#member ul li .li-in .li-head {
  display: flex;
  flex-wrap: wrap;
}

#member ul li .li-in .li-head .li-prof,
#member ul li .li-in .li-head .li-desc {
  display: flex;
  align-items: center;
}

#member ul li .li-in .li-head .li-prof {
  position: relative;
  width: 35%;
  line-height: 0;
}

#member ul li .li-in .li-head .li-prof img {
  opacity: 0;
  transition: opacity 1s .6s;
}

#member ul li .li-in .li-head .li-prof:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: url(../svg/symbol-fill.svg) no-repeat center;
  background-size: contain;
  transform: translate(-50%, -50%) rotateY(360deg) scale(0);
  transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1), opacity 1s 0.6s;
}

#member ul li .li-in .li-head .li-prof.on img {
  opacity: 1;
}

#member ul li .li-in .li-head .li-prof.on:before {
  transform: translate(-50%, -50%) rotateY(0deg) scale(1);
  opacity: 0;
}

#member ul li .li-in .li-head .li-desc {
  width: 65%;
  padding: 0 0 0 20px;
}

#member ul li .li-in .li-head .li-desc h4 {
  color: #fff;
  font-weight: 500;
}

#member ul li .li-in .li-head .li-desc h4 strong {
  display: block;
  font-size: 20px;
}

#member ul li .li-in .li-head .li-desc h4 small {
  display: block;
  font-size: 15px;
}

#member ul li .li-in .li-body {
  margin: 15px 0 0;
}

#member ul li .li-in .li-body p {
  color: #f0e68c;
  font-size: 14px;
}

.sec.sec06 {
  text-align: center;
  padding-bottom: 30px;
}

.sec.sec06 .sec-body .illust {
  line-height: 0;
}

#symbol {
  position: relative;
  top: 100px;
  opacity: 0;
  transform: rotatey(720deg);
  transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1), top 2s cubic-bezier(0.19, 1, 0.22, 1), -webkit-box-shadow 0.65s cubic-bezier(0.19, 1, 0.22, 1), opacity 1s;
}

#symbol img {
  width: 15%;
  max-width: 100px;
}

#symbol.on {
  top: 0;
  opacity: 1;
  transform: rotateY(0deg);
}

#txt-goodluck {
  margin: -10px 0 0;
}

#txt-goodluck img {
  width: 50%;
  max-width: 340px;
}

#txt-forpeople img {
  width: 30%;
  max-width: 190px;
}

#people {
  margin: 30px 0 0;
}

#people img {
  width: 100%;
  max-width: 1000px;
}

@media screen and (min-width: 600px) {
  .sec.sec02 .sec-body .games {
    display: flex;
    flex-wrap: wrap;
    margin: -5% auto -30px;
  }
  .sec.sec02 .sec-body .games .game,
  .sec.sec02 .sec-body .games .game:not(:first-child) {
    display: flex;
    align-items: stretch;
    width: 50%;
    margin: 0 0 30px;
    padding: 0 2.5%;
  }
  .slant {
    padding: 40px 7.5% 60px;
  }
  .slant:before {
    transform: translate(-50%, -50%) skew(-10deg);
  }
  .sec.sec03 .sec-body ul {
    display: flex;
    flex-wrap: wrap;
  }
  .sec.sec03 .sec-body ul li,
  .sec.sec03 .sec-body ul li:not(:first-child) {
    display: flex;
    align-items: stretch;
    width: 50%;
    margin: 0;
    padding: 1.5%;
  }
  .sec.sec04 .sec-body .roadmap {
    margin: auto;
  }
  #member ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 -40px;
  }
  #member ul li {
    display: flex;
    align-items: stretch;
    width: 50%;
    padding: 0 2.5%;
    margin: 0 0 40px;
  }
  #member ul li .li-in .li-head {
    display: block;
    text-align: center;
  }
  #member ul li .li-in .li-head .li-prof,
  #member ul li .li-in .li-head .li-desc {
    display: block;
    width: 100%;
    padding: 0;
  }
  #member ul li .li-in .li-head .li-prof img {
    width: 70%;
    max-width: 400px;
  }
  #member ul li .li-in .li-head .li-desc {
    margin: 15px 0 0;
  }
}

@media screen and (min-width: 800px) {
  #main-desc a.pdf {
    margin: 50px 0 0;
  }
  #main-desc .movie {
    margin: 40px auto 0;
  }
  .sec.sec01 .sec-foot {
    margin: 50px 0 -20px;
  }
  .slant {
    padding: 50px 7.5% 80px;
  }
  .slant:before {
    transform: translate(-50%, -50%) skew(-15deg);
  }
  .sec.sec03 .sec-body ul li,
  .sec.sec03 .sec-body ul li:not(:first-child) {
    width: 33.33%;
  }
  #member ul li {
    width: 33.33%;
  }
}

@media screen and (min-width: 1000px) {
  .slant {
    padding: 60px 7.5% 100px;
  }
  .slant:before {
    transform: translate(-50%, -50%) skew(-20deg);
  }
  .sec.sec03 .sec-body ul li .li-in {
    box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.2);
  }
  #member ul {
    margin: 0 0 -50px;
  }
  #member ul li {
    margin: 0 0 50px;
  }
  #member ul li .li-in .li-head .li-desc h4 strong {
    font-size: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .slant {
    padding: 60px 7.5% 120px;
  }
}

@-webkit-keyframes bgscroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -10000px;
  }
}

@keyframes bgscroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -10000px;
  }
}

#loading span {
  display: block;
  position: absolute;
  top: 70%;
  left: 50%;
  width: 35%;
  max-width: 80px;
  opacity: 0;
  transform: translate(-50%, -50%) rotateY(360deg);
  transition: top 2s cubic-bezier(0.19, 1, 0.22, 1), opacity 2s cubic-bezier(0.19, 1, 0.22, 1), transform 1s 1s cubic-bezier(0.19, 1, 0.22, 1);
}

#loading span svg path,
#loading span svg polyline {
  fill: #fff;
  transition: all .5s;
}

#loading span.on {
  top: 50%;
  opacity: 1;
  transform: translate(-50%, -50%) rotateY(0deg);
}

#loading:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: #FF1E00;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

#loading.on:before {
  width: 200vw;
  height: 200vw;
}

#loading.fill svg path,
#loading.fill svg polyline {
  fill: #FF1E00;
}

#loading.fill:before {
  background-color: #fff;
}
